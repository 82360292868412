
import {
    defineComponent, watch, computed, PropType,
    reactive
} from 'vue';
import { orderType } from '@/data';
import onePrice from './one-price.vue';

export default defineComponent({
    components: {
        onePrice
    },
    props: {
        data: {
            required: true,
            type: Array as PropType< Array<orderType.OrderInfoChildren> >
        },
        total: {
            type: Number,
            required: true
        },
        coupon: {
            type: Number,
            required: true
        },
        before: {
            type: Number,
            required: true
        }
    },
    setup(props) {
        const activity: Array<string> = reactive([]);
        const activityIds: Array<string> = reactive([]);

        watch(computed(() => props.data), () => {
            props.data.forEach((item) => {
                if (item.DiscountInfo && item.ActivityUUID && !activityIds.includes(item.ActivityUUID)) {
                    activity.push(item.DiscountInfo);
                    activityIds.push(item.ActivityUUID);
                }
            });
        }, {
            immediate: true
        });

        return {
            activity
        };
    }
});
