
import {
    defineComponent, PropType
} from 'vue';
import { orderType } from '@/data';
import activity from './activity.vue';

export default defineComponent({
    components: {
        activity
    },
    props: {
        orderDetail: {
            type: Object as PropType<orderType.OrderInfo>,
            required: true
        }
    }
});
