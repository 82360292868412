
import { defineComponent } from 'vue';
import { discountUnitTo100 } from '@/methods/count';

export default defineComponent({
    props: {
        total: {
            type: Number,
            required: true
        },
        coupon: {
            type: Number,
            required: true
        },
        before: {
            type: Number,
            required: true
        }
    },
    setup() {
        const diffOnePrice = (total: number, coupon: number, before: number) => (
            discountUnitTo100(before) === discountUnitTo100(total) + discountUnitTo100(coupon)
        );

        return {
            diffOnePrice
        };
    }
});
